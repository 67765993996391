import React from 'react';
import { Alert, Space } from 'antd'
import IntlMessages from '../../../../../../util/IntlMessages';
import { startsWith } from 'lodash';

const ErrorText = ({ subEvent, error }) => {

    const getColumnName = (column) => {

        if (startsWith(column, 'cf_')) {

            const { custom_fields: customFields, event } = subEvent;

            const { main_language: mainLanguage } = event;

            const customField = (customFields || []).find((field) => field.name === column);

            if (customField) {
                return customField.label[mainLanguage] || customField.name;
            }

        } else {
            return <IntlMessages id={`common.${column}`} />;
        }
    }


    if (error.message) {

        if (error.row < 0) {
            return <span><IntlMessages id={`import_players.error.${error.message}`} /></span>;
        } else {

            return (
                <Space>
                    <span><IntlMessages id="common.row" /> {error.row}</span> -
                    <span><IntlMessages id="common.column" /> <strong>{getColumnName(error.column)}</strong></span>:
                    <span>{<IntlMessages id={`import_players.error.${error.message}`} />}</span>
                </Space>
            );
        }
    }

    return <div>{error}</div>;
}

const ValidationErrors = ({ subEvent, title = '', errors = [], onClose = null }) => {

    if (!errors || errors.length === 0) {
        return null;
    }

    return (
        <div>
            <Alert
                message={title || <IntlMessages id="common.errors" />}
                type="error"
                showIcon={true}
                closable={Boolean(onClose)}
                onClose={onClose}
                description={(
                    <div>
                        <ul>
                            {errors.map((error, index) => (
                                <li key={index}>
                                    <ErrorText subEvent={subEvent} error={error} />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            />
        </div>
    );
}

export default ValidationErrors;