import { Button, Card, message, Space } from "antd";
import React from "react";
import IntlMessages from "../../../../../../util/IntlMessages";
import { gql, useLazyQuery } from "@apollo/client";
import { getSafeISOLanguage } from "../../../../../../lngProvider";
import { downloadBase64File } from "../../../../../../util/file";
import { defaultCatchException } from "../../../../../../apollo/callbacks";
import { FileExcelOutlined } from "@ant-design/icons";
import TipsCard from "../../../../../../components/TipsCard";

const DOWNLOAD_SUB_EVENT_SUBSCRIPTIONS_TEMPLATE = gql`
    query downloadSubEventSubscriptionsTemplate($id: String!, $locale: String) {
        downloadSubEventSubscriptionsTemplate(id: $id, locale: $locale) {
            content
            filename            
        }
    }
`

const DownloadFileStep = ({ subEvent, setStepState, intl }) => {

    const [downloadTemplate, { loading }] = useLazyQuery(DOWNLOAD_SUB_EVENT_SUBSCRIPTIONS_TEMPLATE, {
        fetchPolicy: 'no-cache',
    })

    return (
        <>
            <Card title={<IntlMessages id="import_players.download_template" />}>
                <p className="gx-p-5 _gx-text-center">

                    <TipsCard>
                        <IntlMessages id="import_players.download_template_tip" />
                    </TipsCard>
                    <Space>
                        <Button
                            loading={loading}
                            size="large"
                            type="primary"
                            onClick={() => {
                                downloadTemplate({
                                    variables: {
                                        id: subEvent.id,
                                        locale: getSafeISOLanguage(intl.locale),
                                    },
                                })
                                    .then(({ data }) => {
                                        if (data?.downloadSubEventSubscriptionsTemplate) {
                                            const { filename, content } = data.downloadSubEventSubscriptionsTemplate;

                                            if (filename && content) {
                                                downloadBase64File(filename, content);
                                                setStepState(1);
                                            } else {
                                                message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
                                            }
                                        } else {
                                            message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
                                        }
                                    })
                                    .catch((e) => defaultCatchException(e, intl));
                            }}
                        >
                            <FileExcelOutlined /> <IntlMessages id="import_players.download_template_btn" />
                        </Button>

                        <Button
                            size="large"
                            onClick={() => {
                                setStepState(1);
                            }}
                        >
                            <IntlMessages id="import_players.go_next" />
                        </Button>
                    </Space>
                </p>
            </Card>
        </>
    )
}

export default DownloadFileStep;