import { Checkbox, Select, Input } from 'antd';
import React from 'react';
import { CUSTOM_FIELD_TYPES } from '../../constants/App';

const SelectField = ({ field, ...rest }) => {
    const { options } = field;
    return (
        <Select {...rest}>
            {options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label[rest.language]}
                </Select.Option>
            ))}
        </Select>
    );
};

const TextField = ({ field, ...rest }) => {
    return <Input {...rest} />;
};

const TextAreaField = ({ field, ...rest }) => {
    return <Input.TextArea {...rest} />;
};

const CheckboxField = ({ field, ...rest }) => {
    return <Checkbox {...rest}/>;
};

const CustomField = ({ field, ...rest }) => {
    const { custom_type: customType } = field;

    switch (customType) {
        case CUSTOM_FIELD_TYPES.SELECT:
            return <SelectField field={field} {...rest} />;
        case CUSTOM_FIELD_TYPES.INPUT_TEXT:
            return <TextField field={field} {...rest} />;
        case CUSTOM_FIELD_TYPES.TEXTAREA:
            return <TextAreaField field={field} {...rest} />;
        case CUSTOM_FIELD_TYPES.CHECKBOX:
            return <CheckboxField field={field} {...rest} />;
        default:
            return <div>Not implemented</div>;
    }
};

export default CustomField;
