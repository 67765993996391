import { useQuery } from '@apollo/client';
import { Skeleton } from 'antd';
import React, { useEffect, useContext } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { EVENT_CONTAINER_DATA } from '../../../apollo/query/event';
import { MENU_TYPE } from '../../../constants/App';
import { MenuContext } from '../../../contexts/MenuContext';
import { getAdminPath } from '../../../util/router';
import InsertSubEventForm from './insert';
import SubEventDashboard from './dashboard';
import SubEventInformations from './informations';
import SubEventSubscriptionModule from './subscriptionsModule';
import SubEventPrices from './prices';
import SubEventPlayers from './players';
import SubEventPlayersTracking from './playersTracking';
import ViewSubEventPlayer from './players/view';
import UpdateSubEventPlayer from './players/update';
import SubEventDescription from './description';
import AttachmentSubEvent from './attachments';
import SubEventCommunications from './communications';
import SubEventImportPlayers from './players/import';

const SubEventRoutes = ({ event }) => {
    const { menu, setMenu } = useContext(MenuContext);

    useEffect(() => {
        if (menu.type !== MENU_TYPE.EVENT) {
            setMenu({
                type: MENU_TYPE.EVENT,
                event: event,
            });
        }
    }, [menu.type]);

    return (
        <div>
            <Switch>
                <Route exact path="/admin/event/:event_id/sub-event/:id/dashboard" component={SubEventDashboard} />
                <Route exact path="/admin/event/:event_id/sub-event/:id/informations" component={SubEventInformations} />
                <Route exact path="/admin/event/:event_id/sub-event/:id/subscription-module" component={SubEventSubscriptionModule} />
                <Route exact path="/admin/event/:event_id/sub-event/:id/prices" component={SubEventPrices} />
                <Route exact path={getAdminPath('event/:event_id/sub-event/:id/subscribers')} component={SubEventPlayers} />
                <Route exact path={getAdminPath('event/:event_id/sub-event/:id/subscribers/import')} component={SubEventImportPlayers} />
                <Route exact path={getAdminPath('event/:event_id/sub-event/:id/tracking')} component={SubEventPlayersTracking} />
                <Route path={getAdminPath('event/:event_id/sub-event/:id/subscriber/view/:player_id')} component={ViewSubEventPlayer} />
                <Route path={getAdminPath('event/:event_id/sub-event/:id/subscriber/update/:player_id')} component={UpdateSubEventPlayer} />
                <Route exact path={getAdminPath('event/:event_id/sub-event/:id/description')} component={SubEventDescription} />
                {/*                                
                <Route exact path="/admin/event/:id/sub-event/:id/gallery" component={SubEventGallery} /> */}
                <Route exact path="/admin/event/:id/sub-event/:id/attachments" component={AttachmentSubEvent} />
                <Route exact path={getAdminPath('event/:id/sub-event/insert')} component={InsertSubEventForm} />
                <Route exact path={getAdminPath('event/:id/sub-event/:id/communications')} component={SubEventCommunications} />
            </Switch>
        </div>
    );
};

const SubEventContainer = ({ match }) => {
    const { params } = match;

    const { data, loading, error } = useQuery(EVENT_CONTAINER_DATA, { variables: { id: params.id } });

    if (loading) {
        return <Skeleton active={true} />;
    }

    if (error || !data.event) {
        return <Redirect to="/admin" />;
    }

    return <SubEventRoutes event={data?.event} />;
};

export default SubEventContainer;
