function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    const byteString = window.atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const arrayBuffer = new ArrayBuffer(byteString.length);
    let _ia = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        _ia[i] = byteString.charCodeAt(i);
    }

    const dataView = new DataView(arrayBuffer);
    const blob = new Blob([dataView], { type: mimeString });
    return blob;
}

export const decodeBase64 = function (s) {
    let e = {},
        i,
        b = 0,
        c,
        x,
        l = 0,
        a,
        r = '',
        w = String.fromCharCode,
        L = s.length;
    let A = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    for (i = 0; i < 64; i++) {
        e[A.charAt(i)] = i;
    }
    for (x = 0; x < L; x++) {
        c = e[s.charAt(x)];
        b = (b << 6) + c;
        l += 6;
        while (l >= 8) {
            ((a = (b >>> (l -= 8)) & 0xff) || x < L - 2) && (r += w(a));
        }
    }
    return r;
};

export const downloadBase64File = (filename, content) => {
    const blob = dataURItoBlob(content);
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = filename;
    downloadLink.onclick = function () {
        requestAnimationFrame(function () {
            URL.revokeObjectURL(url);
        });
    };
    downloadLink.click();
};

export const openDocumentWindow = (base64Doc, { title = 'Documento' }) => {
    var popupWin = window.open('', '_blank');
    var keepColors = '<style>body {margin:0;overflow:hidden;}</style>';

    const blob = dataURItoBlob(base64Doc);

    const url = URL.createObjectURL(blob);

    setTimeout(() => {
        popupWin.document.write(`
            <html>
                <head>
                    <title>${title}</title>
                    ${keepColors}
                </head>
                <body>
                    <embed width="100%" height="100%" src="${url}"></embed>
                </body>
            </html>
        `);
    }, 0);
};


export function fileToBase64(file) {
    return new Promise((resolve, reject) => {
       const reader = new FileReader();
       reader.readAsDataURL(file);
       reader.onload = () => resolve(reader.result);
       reader.onerror = (error) => reject(error);
    });
 }