
import diff from "microdiff";
import { isDevelopment } from "./config";

const printAction = (action) => {
    if (isDispatchThunk(action)) {
        return 'Thunk';
    } else {
        return action.type;
    }
}


export function isDispatchThunk(action) {
    return typeof action === "function";
}

export const logPreviousState = (action, state) => {
    if (!isDevelopment) return;
    console.group(`BEFORE ACTION %c${printAction(action)}`, 'color: red; font-weight: bold;');
    console.log(`Payload[${JSON.stringify(action.payload)}]`);
    console.log('INIT STATE:', state);
    console.groupEnd();
};
export const logNextState = (action, previousState, state) => {
    if (!isDevelopment) return;

    console.group(`STATE DIFF %c${printAction(action)}`, 'color: yellow; font-weight: bold;');
    console.log(JSON.stringify(diff(previousState, state), null, 2));
    console.groupEnd();

    console.group(`AFTER ACTION %c${printAction(action)}`, 'color: green; font-weight: bold;');
    console.log(`Payload[${JSON.stringify(action.payload)}]`);
    console.log('FINAL STATE:', state);
    console.groupEnd();
};

