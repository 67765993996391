import React, { useContext } from 'react';
import { Space, Table, Button, Form, Input, Select, DatePicker } from 'antd'
import IntlMessages from '../../../../../../util/IntlMessages';
import { CheckCircleOutlined, CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { ImportPlayersContext } from '../context';
import CountrySelect from '../../../../../../components/LocationSelect/CountrySelect';
import CustomField from '../../../../../../components/CustomField';
import { CUSTOM_FIELD_TYPES, DATE_FORMAT } from '../../../../../../constants/App';
import LocationSelect from '../../../../../../components/LocationSelect';
import moment from 'moment';
import { startsWith, get, keyBy } from 'lodash';

const isEditing = (record, editingKey) => record.index === editingKey;

const getProductVariantName = (variant, language) => {
    return variant.attributes.map((attribute) => {

        const attributeName = get(attribute, `attribute_name.${language}`, '');
        const attributeValue = get(attribute, `option_name.${language}`, '');

        return <span>{attributeName}: {attributeValue}</span>;
    });
}

const getInputProps = (col, record, form) => {

    if (col.key === 'birthday') {
        return { name: 'date' };
    }

    if (col.key === 'citizenship') {
        return { name: 'country', trigger: 'onSelect' };
    }

    if (col.key === 'gender') {
        return { name: 'gender' }
    }

    if (col.customField) {

        if (col.customField?.custom_type === CUSTOM_FIELD_TYPES.CHECKBOX) {
            return { name: 'custom', valuePropName: 'checked' };
        }

        return { name: 'custom' };
    }

    if (col.key === 'location') {

        return {
            name: 'location',
            trigger: 'onSelect',
            getValueFromEvent: (value, selectOption) => {
                const { label } = selectOption?.option || {};

                form.setFieldsValue({
                    [`players.${record.index}.${col.dataIndex}_label`]: label
                });

                return value;
            }
        };
    }

    if (startsWith(col.key, 'product')) {
        return {
            name: 'product',
        }
    }

    return { name: 'text' };
}

const prepareEditableColumns = (columns, editingKey, form) => {
    return columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputProps: getInputProps(col, record, form),
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record, editingKey),
                required: col.required,
                language: col.language,
                customField: col.customField,
                product: col.product,
            }),
        };
    })
}

const ColumnTitle = ({ transKey, required }) => {
    return (
        <span>
            <IntlMessages id={`common.${transKey}`} />
            {required ? <span className='gx-text-danger'>*</span> : null}
        </span>
    )
}

/**
 * creare una mappa delle location selezionate per visualizzare la label e non l'id
 * oppure creare un campo hidden per salvare il rif alla location
 * */
const getColumns = ({ subEvent, onDeleteRow, editingKey, onEditRow, onSaveRow, form, editable }) => {

    const { predefined_fields: predefinedFields, products_id, event } = subEvent;

    const { main_language: language, products } = event;

    const subEventProducts = products.filter((product) => products_id.includes(product.id));

    const subEventProductMap = keyBy(subEventProducts, 'id');

    let columns = [
        {
            title: <ColumnTitle transKey='first_name' required={true} />,
            dataIndex: 'first_name',
            key: 'first_name',
            editable: true,
            required: true,
        },
        {
            title: <ColumnTitle transKey='last_name' required={true} />,
            dataIndex: 'last_name',
            key: 'last_name',
            editable: true,
            required: true,
        },
        {
            title: <ColumnTitle transKey='email' required={true} />,
            dataIndex: 'email',
            key: 'email',
            editable: true,
            required: true,
        },
        {
            title: <ColumnTitle transKey='gender' required={true} />,
            dataIndex: 'gender',
            key: 'gender',
            editable: true,
            required: true,
            render(value) {
                if (value === 'm') {
                    return <IntlMessages id='common.male' />
                } else if (value === 'f') {
                    return <IntlMessages id='common.female' />
                } else {
                    return null;
                }
            }
        },
        {
            title: <ColumnTitle transKey='birthday' required={true} />,
            dataIndex: 'birthday',
            key: 'birthday',
            editable: true,
            required: true,
            render(value) {
                return value ? moment(value, 'DD/MM/YYYY').format(DATE_FORMAT) : null;
            }
        },
        predefinedFields?.phone?.active ?
            {
                title: <ColumnTitle transKey='phone' required={predefinedFields.phone?.required} />,
                dataIndex: 'phone',
                key: 'phone',
                editable: true,
                required: predefinedFields.phone?.required,
            } : null,
        predefinedFields?.citizenship?.active ?
            {
                title: <ColumnTitle transKey='citizenship' required={predefinedFields.citizenship?.required} />,
                dataIndex: 'citizenship',
                key: 'citizenship',
                editable: true,
                required: predefinedFields.citizenship?.required,
            } : null,
        predefinedFields?.location?.active ?
            {
                title: (
                    <span>
                        <IntlMessages id="common.location" />
                        {predefinedFields.location?.required ? <span className='gx-text-danger'>*</span> : null}
                    </span>
                ),
                dataIndex: 'location',
                key: 'location',
                editable: true,
                required: predefinedFields.location?.required,
                render: (value, row) => {

                    if (value && row.location_label) {
                        return row.location_label;
                    } else if (value) {
                        return <CheckCircleOutlined />;
                    } else {
                        return null;
                    }
                }
            } : null,
    ].filter(Boolean)

    const { custom_fields: customFields } = subEvent;

    if (customFields && customFields.length) {
        customFields.forEach((customField) => {
            columns.push({
                title: (
                    <span>{customField.label[language]} {customField.required ? <span className='gx-text-danger'>*</span> : null}</span>
                ),
                dataIndex: customField.name,
                key: customField.name,
                customField: customField,
                language: language,
                editable: true,
                required: customField.required,
                render: (value, row) => {

                    if (customField.custom_type === CUSTOM_FIELD_TYPES.SELECT) {
                        const option = customField.options.find((option) => option.value === value);
                        return option ? option.label[language] : value;
                    } else if (customField.custom_type === CUSTOM_FIELD_TYPES.CHECKBOX) {
                        return value ? <CheckCircleOutlined /> : null;
                    }

                    return value;
                }
            });
        });
    }

    if (subEventProducts.length) {
        subEventProducts.forEach((product) => {
            columns.push({
                title: product.name,
                dataIndex: `product_${product.id}`,
                key: `product_${product.id}`,
                language: language,
                editable: true,
                required: false,
                product: product,
                render: (value) => {
                    const _product = subEventProductMap[product.id];
                    const variant = _product.variants.find((variant) => variant.sku === value);
                    if (variant) {
                        return getProductVariantName(variant, language);
                    }
                    return null;
                }
            })
        })
    }

    if (editable) {
        columns.push({
            title: <IntlMessages id="common.actions" />,
            dataIndex: 'index',
            key: 'index',
            className: 'gx-text-center',
            fixed: 'right',
            render: (value, row) => {

                const editing = isEditing(row, editingKey);

                return editing ? (
                    <Space>
                        <Button className='gx-mb-0' icon={<CloseOutlined />} onClick={() => onEditRow(null)} />
                        <Button type='primary' className='gx-mb-0' icon={<SaveOutlined />} onClick={() => onSaveRow(value)} />
                    </Space>
                ) : (
                    <Space>
                        <Button className='gx-mb-0' danger icon={<DeleteOutlined />} onClick={() => onDeleteRow(value)} />
                        <Button className='gx-mb-0' icon={<EditOutlined />} onClick={() => onEditRow(value)} />
                    </Space>
                )
            }
        });
    }

    return prepareEditableColumns(columns, editingKey, form);
}

const getInputNode = ({ required, inputProps, customField, product, language, record, dataIndex }) => {

    const commonFieldProps = {
        className: 'gx-w-100',
    }

    const commonFormItemProps = {
        name: `players.${record.index}.${dataIndex}`,
        //name: ['players', record.index, dataIndex],
        className: 'gx-mx-2 gx-my-0',
        trigger: inputProps.trigger ? inputProps.trigger : 'onChange',
        valuePropName: inputProps.valuePropName ? inputProps.valuePropName : 'value',
        getValueFromEvent: inputProps.getValueFromEvent ? inputProps.getValueFromEvent : undefined,
        rules: [
            {
                required: required,
                message: <IntlMessages id='common.field_required' />,
            }
        ]
    };

    switch (inputProps.name) {
        case 'country':
            return (
                <Form.Item
                    {...commonFormItemProps}
                >
                    <CountrySelect {...commonFieldProps} />
                </Form.Item>
            )
        case 'gender':
            return (
                <Form.Item
                    {...commonFormItemProps}
                >
                    <Select {...commonFieldProps}>
                        <Select.Option value="m">
                            <IntlMessages id="common.male" />
                        </Select.Option>
                        <Select.Option value="f">
                            <IntlMessages id="common.female" />
                        </Select.Option>
                    </Select>
                </Form.Item>
            )
        case 'custom':
            return (
                <Form.Item
                    {...commonFormItemProps}
                >
                    <CustomField {...commonFieldProps} field={customField} language={language} />
                </Form.Item>
            )
        case 'product': {
            return (
                <Form.Item
                    {...commonFormItemProps}
                >
                    <Select {...commonFieldProps}>

                        <Select.Option value={null}>
                            (<IntlMessages id="common.empty" />)
                        </Select.Option>
                        {product.variants.map((variant, index) => {
                            return (
                                <Select.Option key={index} value={variant.sku}>
                                    {getProductVariantName(variant, language)}
                                </Select.Option>
                            );
                        })}

                    </Select>
                </Form.Item>
            )
        }
        case 'location':
            return (
                <>
                    <Form.Item
                        {...commonFormItemProps}
                    >
                        <LocationSelect style={{ minWidth: 200 }} />
                    </Form.Item >
                    <Form.Item
                        name={`players.${record.index}.${dataIndex}_label`}
                        noStyle
                    >
                        <Input type='hidden' />
                    </Form.Item>
                </>
            )
        case 'date':
            return (
                <Form.Item
                    {...commonFormItemProps}
                >
                    <DatePicker {...commonFieldProps} format={DATE_FORMAT} showToday={false} />
                </Form.Item>
            )
        default:
            return (
                <Form.Item
                    {...commonFormItemProps}
                >
                    <Input {...commonFieldProps} />
                </Form.Item>
            )
    }
};

/**
 * prepare the cell to be editable (non ci sono i valori)
 * @param {*} param0 
 * @returns 
 */
const EditableCell = ({
    editing,
    required,
    customField,
    product,
    language,
    dataIndex,
    title,
    inputProps,
    record,
    index,
    children,
    ...restProps
}) => {

    return (
        <td {...restProps}>
            {editing ? (
                getInputNode({ required, inputProps, customField, product, language, record, dataIndex })
            ) : (
                children
            )}
        </td >
    );
};

const getFormValues = (players, index) => {

    const player = players.find((player) => player.index === index);

    if (!player) {
        return {};
    }

    let values = {};

    const keys = Object.keys(player);

    keys.forEach((key) => {
        const fieldName = `players.${player.index}.${key}`;

        if (key === 'birthday') {
            values[fieldName] = player[key] ? moment(player[key], 'DD/MM/YYYY') : null;
        } else {
            values[fieldName] = player[key];
        }
    });

    return values;
}

const ImportPlayersTable = ({
    subEvent,
    editable = null,
    onSubmit = null,
    form = null,
}) => {

    const { state, setPlayers, setEditingKey } = useContext(ImportPlayersContext);

    const { players, editingKey } = state;

    const onDeleteRow = (index) => {

        const newPlayers = players.filter((player) => player.index !== index);

        setPlayers(newPlayers);
    }

    const onEditRow = (index) => {

        form.setFieldsValue(getFormValues(players, index));
        setEditingKey(index);
    }

    const onSaveRow = async (index) => {

        try {
            const values = await form.validateFields();

            const newData = [...players];

            const updatedIndex = newData.findIndex((item) => index === item.index);

            if (updatedIndex > -1) {
                const item = newData[updatedIndex];

                // Object.keys(item).forEach((key) => {
                //     if (values[`players.${index}.${key}`]) {
                //         item[key] = values[`players.${index}.${key}`];
                //     }
                // });

                //use values keys to update item
                Object.keys(values).forEach((key) => {
                    const [_, playerIndex, playerKey] = key.split('.');
                    if (playerIndex === index.toString()) {
                        item[playerKey] = values[key];
                    }

                });

                newData.splice(updatedIndex, 1, { ...item });
                setPlayers(newData);
            }

            setEditingKey(null);
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    }

    if (editable) {

        return (
            <div>
                <Form
                    onFinish={() => {
                        onSubmit(players);
                    }}
                    form={form}
                    component={false}
                >
                    <Table
                        scroll={{ x: 'max-content' }}
                        rowKey={(record) => record.index}
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        columns={getColumns({ subEvent, editable, onDeleteRow, editingKey, onEditRow, onSaveRow, form })}
                        dataSource={players}
                    />
                </Form>
            </div >
        );
    } else {
        return (
            <Table
                rowKey={(record) => record.index}
                columns={getColumns({ subEvent, editable })}
                dataSource={players}
            />
        )
    }
};

export default ImportPlayersTable;