import React from 'react';
import { Row, Col, Form, Select, Card, Input, Button, Typography } from 'antd';
import IntlMessages from '../../../../util/IntlMessages';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const LocationInput = ({ intl }) => {
    return (
        <Card
            className="gx-card"
            title={
                <span>
                    {intl.formatMessage({ id: 'common.field' })} | {intl.formatMessage({ id: 'common.residence_location' })}
                </span>
            }
        >
            <Form.Item initialValue={false} name={['predefined', 'location', 'active']} label={<IntlMessages id="common.active" />}>
                <Select>
                    <Select.Option value={true}>
                        <IntlMessages id="common.yes" />
                    </Select.Option>
                    <Select.Option value={false}>
                        <IntlMessages id="common.no" />
                    </Select.Option>
                </Select>
            </Form.Item>

            <Form.Item initialValue={false} name={['predefined', 'location', 'required']} label={<IntlMessages id="common.mandatory" />}>
                <Select>
                    <Select.Option value={true}>
                        <IntlMessages id="common.yes" />
                    </Select.Option>
                    <Select.Option value={false}>
                        <IntlMessages id="common.no" />
                    </Select.Option>
                </Select>
            </Form.Item>
        </Card>
    );
};

const PhoneInput = ({ intl }) => {
    return (
        <Card
            className="gx-card"
            title={
                <span>
                    {intl.formatMessage({ id: 'common.field' })} | {intl.formatMessage({ id: 'common.phone' })}
                </span>
            }
        >
            <Form.Item initialValue={false} name={['predefined', 'phone', 'active']} label={<IntlMessages id="common.active" />}>
                <Select>
                    <Select.Option value={true}>
                        <IntlMessages id="common.yes" />
                    </Select.Option>
                    <Select.Option value={false}>
                        <IntlMessages id="common.no" />
                    </Select.Option>
                </Select>
            </Form.Item>

            <Form.Item initialValue={false} name={['predefined', 'phone', 'required']} label={<IntlMessages id="common.mandatory" />}>
                <Select>
                    <Select.Option value={true}>
                        <IntlMessages id="common.yes" />
                    </Select.Option>
                    <Select.Option value={false}>
                        <IntlMessages id="common.no" />
                    </Select.Option>
                </Select>
            </Form.Item>
        </Card>
    );
};

const AthleteCardInput = ({ intl }) => {
    return (
        <Card
            className="gx-card"
            title={
                <span>
                    {intl.formatMessage({ id: 'common.field' })} | {intl.formatMessage({ id: 'common.athlete_card' })}
                </span>
            }
        >
            <Row>
                <Col xs={24} md={12}>
                    <Form.Item initialValue={false} name={['predefined', 'athlete_card', 'active']} label={<IntlMessages id="common.active" />}>
                        <Select>
                            <Select.Option value={true}>
                                <IntlMessages id="common.yes" />
                            </Select.Option>
                            <Select.Option value={false}>
                                <IntlMessages id="common.no" />
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item initialValue={false} name={['predefined', 'athlete_card', 'required']} label={<IntlMessages id="common.mandatory" />}>
                        <Select>
                            <Select.Option value={true}>
                                <IntlMessages id="common.yes" />
                            </Select.Option>
                            <Select.Option value={false}>
                                <IntlMessages id="common.no" />
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} md={24}>
                    <Typography.Paragraph className="_gx-font-weight-bold gx-text-uppercase">
                        <IntlMessages id="common.options" />
                    </Typography.Paragraph>
                    <Form.List name={['predefined', 'athlete_card', 'options']}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => {
                                    return (
                                        <div className="gx-bg-light-grey gx-p-3 gx-mb-3 gx-border gx-border-primary" style={{ borderStyle: 'dashed' }}>
                                            <Row>
                                                <Form.Item {...restField} name={[name, 'value']} noStyle>
                                                    <Input type="hidden" />
                                                </Form.Item>
                                                <Col xs={24} md={10} className="gx-pl-0 ">
                                                    <Form.Item
                                                        {...restField}
                                                        label={intl.formatMessage({ id: 'common.name' })}
                                                        name={[name, 'label']}
                                                        rules={[{ required: true, message: intl.formatMessage({ id: 'common.field_required' }) }]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={10}>
                                                    <Form.Item
                                                        {...restField}
                                                        initialValue=""
                                                        label={intl.formatMessage({ id: 'common.type' })}
                                                        name={[name, 'type']}
                                                    >
                                                        <Select>
                                                            <Select.Option value="">
                                                                <IntlMessages id="common.none" />
                                                            </Select.Option>
                                                            <Select.Option value="fidal">
                                                                Fidal - <IntlMessages id="common.italy" />
                                                            </Select.Option>
                                                            <Select.Option value="runcard">
                                                                Runcard - <IntlMessages id="common.italy" />
                                                            </Select.Option>
                                                            <Select.Option value="fitri">
                                                                Fitri - <IntlMessages id="common.italy" />
                                                            </Select.Option>
                                                            <Select.Option value="csi">
                                                                CSI - <IntlMessages id="common.italy" />
                                                            </Select.Option>
                                                            <Select.Option value="acsi">
                                                                ACSI - <IntlMessages id="common.italy" />
                                                            </Select.Option>
                                                            <Select.Option value="cycling_coni">
                                                                Tesserato (Enti di promozione sportiva ciclismo) - <IntlMessages id="common.italy" />
                                                            </Select.Option>
                                                            <Select.Option value="identity_card">
                                                                Non Tesserato (<IntlMessages id="common.identity_card" />)
                                                            </Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={4}>
                                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                                </Col>
                                                <Col xs={24} md={6} className="gx-pl-0">
                                                    <Form.Item
                                                        initialValue={false}
                                                        name={[name, 'certificate_required']}
                                                        label={<IntlMessages id="common.medical_certificate" />}
                                                    >
                                                        <Select>
                                                            <Select.Option value={true}>
                                                                <IntlMessages id="common.yes" />
                                                            </Select.Option>
                                                            <Select.Option value={false}>
                                                                <IntlMessages id="common.no" />
                                                            </Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={6}>
                                                    <Form.Item
                                                        initialValue={true}
                                                        name={[name, 'card_number']}
                                                        label={<IntlMessages id="common.athlete_card_number" />}
                                                    >
                                                        <Select>
                                                            <Select.Option value={true}>
                                                                <IntlMessages id="common.yes" />
                                                            </Select.Option>
                                                            <Select.Option value={false}>
                                                                <IntlMessages id="common.no" />
                                                            </Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={6}>
                                                    <Form.Item
                                                        initialValue={false}
                                                        name={[name, 'card_upload']}
                                                        label={<IntlMessages id="common.athlete_card_upload" />}
                                                    >
                                                        <Select>
                                                            <Select.Option value={true}>
                                                                <IntlMessages id="common.yes" />
                                                            </Select.Option>
                                                            <Select.Option value={false}>
                                                                <IntlMessages id="common.no" />
                                                            </Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                })}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        <IntlMessages id="common.add_option" />
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>
        </Card>
    );
};

const CitizenshipInput = ({ intl }) => {
    return (
        <Card
            className="gx-card"
            title={
                <span>
                    {intl.formatMessage({ id: 'common.field' })} | {intl.formatMessage({ id: 'common.citizenship_country' })}
                </span>
            }
        >
            <Form.Item initialValue={false} name={['predefined', 'citizenship', 'active']} label={<IntlMessages id="common.active" />}>
                <Select>
                    <Select.Option value={true}>
                        <IntlMessages id="common.yes" />
                    </Select.Option>
                    <Select.Option value={false}>
                        <IntlMessages id="common.no" />
                    </Select.Option>
                </Select>
            </Form.Item>

            <Form.Item initialValue={false} name={['predefined', 'citizenship', 'required']} label={<IntlMessages id="common.mandatory" />}>
                <Select>
                    <Select.Option value={true}>
                        <IntlMessages id="common.yes" />
                    </Select.Option>
                    <Select.Option value={false}>
                        <IntlMessages id="common.no" />
                    </Select.Option>
                </Select>
            </Form.Item>
        </Card>
    );
};

const PredefinedFields = ({ intl }) => {
    return (
        <Row className="gx-row-form-child">
            <Col xs={24} md={12}>
                <LocationInput intl={intl} />
            </Col>
            <Col xs={24} md={12}>
                <PhoneInput intl={intl} />
            </Col>
            <Col xs={24} md={12}>
                <CitizenshipInput intl={intl} />
            </Col>
            <Col xs={24} md={24}>
                <AthleteCardInput intl={intl} />
            </Col>
        </Row>
    );
};
export default PredefinedFields;
