import { Button, Result } from "antd";
import IntlMessages from "../../../../../../util/IntlMessages";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { ImportPlayersContext } from "../context";

const CompleteImportStep = ({ subEvent }) => {

    const { resetState } = useContext(ImportPlayersContext);

    return (
        <Result
            status="success"
            icon={<CheckCircleOutlined />}
            title={<IntlMessages id="import_players.import_success" />}
            extra={[
                <Link to={`/admin/event/${subEvent.event.id}/sub-event/${subEvent.id}/subscribers`} className="ant-btn" key="link">
                    <IntlMessages id="import_players.go_to_players" />
                </Link>,
                <Button
                    type="primary"
                    key="new"
                    onClick={() => {
                        resetState();
                    }}
                >
                    <IntlMessages id="import_players.new_import" />
                </Button>

            ]}
        />
    )
}

export default CompleteImportStep;