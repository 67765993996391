import { Form, Row, Col, Card, Radio, Input, Button, Space } from "antd";
import React, { useContext } from "react";
import IntlMessages from "../../../../../../util/IntlMessages";
import { ImportPlayersContext, playersToMutation } from "../context";
import ImportPlayersTable from "../components/ImportPlayersTable";
import { IMPORT_SUB_EVENT_SUBSCRIPTIONS } from "../../../../../../apollo/mutation/sub-event";
import { useMutation } from "@apollo/client";
import { emailRule, requiredRule } from "../../../../../../util/form";
import { useIntl } from "react-intl";
import { SaveOutlined } from "@ant-design/icons";
import ValidationErrors from "../components/ValidationErrors";

const PayerForm = () => {

    const intl = useIntl()

    return (
        <div className="gx-px-3">
            <Form.Item
                name="payment_creation_type"
                rules={[requiredRule(intl)]}
            >
                <Radio.Group>
                    <Radio value={1}>
                        <IntlMessages id="import_players.create_single_payment" />
                    </Radio>
                    <Radio value={2}>
                        <IntlMessages id="import_players.create_multiple_payments" />
                    </Radio>
                </Radio.Group>
            </Form.Item>


            <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                    prevValues.payment_creation_type !== curValues.payment_creation_type
                }
            >
                {({ getFieldValue }) =>
                    getFieldValue('payment_creation_type') === 1 ?
                        (
                            <Row className="gx-bg-light gx-p-4">
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label={<IntlMessages id="common.first_name" />}
                                        name={'first_name'}
                                        rules={[requiredRule(intl)]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label={<IntlMessages id="common.last_name" />}
                                        name={'last_name'}
                                        rules={[requiredRule(intl)]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label={<IntlMessages id="common.email" />}
                                        name={'email'}
                                        rules={[requiredRule(intl), emailRule(intl)]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : null
                }
            </Form.Item>

        </div>
    )
}

const PayerStep = ({ subEvent }) => {

    const { state,  setValidatorState, setStepState } = useContext(ImportPlayersContext);

    const { players, validatorState } = state;

    const [importSubEventSubscriptions, { loading: loadingMutation }] = useMutation(IMPORT_SUB_EVENT_SUBSCRIPTIONS);

    return (
        <Form
            layout="vertical"
            initialValues={{
                payment_creation_type: 2,
            }}
            onFinish={(values) => {
                importSubEventSubscriptions({
                    variables: {
                        id: subEvent.id,
                        players: playersToMutation(players, subEvent),
                        payer: {
                            mode: values.payment_creation_type,
                            first_name: values.first_name?.trim(),
                            last_name: values.last_name?.trim(),
                            email: values.email?.trim(),
                        },
                        dryRun: false,
                    }
                }).then(({ data }) => {
                    if (data?.importSubEventSubscriptions) {
                        if (data.importSubEventSubscriptions.errors && data.importSubEventSubscriptions.errors.length > 0) {

                            setValidatorState({
                                errors: data.importSubEventSubscriptions.errors
                            });

                        } else {

                            setValidatorState({
                                errors: []
                            });

                            setStepState(3);
                        }
                    }
                }).catch((error) => {
                    console.log('error', error);
                });
            }}
        >
            <Row>
                <Col xs={24}>

                    <ValidationErrors subEvent={subEvent} errors={validatorState.errors} />

                    <Card
                        title={<IntlMessages id="import_players.payer" />}
                        extra={(
                            <Space>
                                <Button
                                    loading={loadingMutation}
                                    type="primary"
                                    className="gx-mb-0"
                                    htmlType="submit"
                                    icon={<SaveOutlined className="gx-pr-1" />}
                                    disabled={loadingMutation}
                                >
                                    <IntlMessages id="common.save" />
                                </Button>
                            </Space>
                        )}
                    >
                        <PayerForm />
                    </Card>
                </Col>
                <Col xs={24}>
                    <Card
                        title={<IntlMessages id="import_players.players" />}
                    >
                        <ImportPlayersTable subEvent={subEvent} editable={false} />
                    </Card>
                </Col>
            </Row>
        </Form>
    )
}

export default PayerStep;    