import React, { useContext } from "react";
import IntlMessages from "../../../../../util/IntlMessages";
import { Col, PageHeader, Row, Steps } from "antd";
import { useIntl } from "react-intl";
import { SUB_EVENT_IMPORT_PLAYERS } from "../../../../../apollo/query/sub-event";
import CircularProgress from "../../../../../components/CircularProgress";
import BadResponse from "../../../../../components/BadResponse";
import { Redirect, useHistory } from "react-router-dom";
import { getAdminPath } from "../../../../../util/router";
import { useQuery } from "@apollo/client";
import PlayersImportDownloadFile from "./steps/DownloadFileStep";
import CreateRowsStep from "./steps/CreateRowsStep";
import { ImportPlayersContext, ImportPlayersProvider } from "./context";
import DevButton from "../../../../../components/Dev/DevButton";
import PayerStep from "./steps/PayerStep";
import CompleteImportStep from "./steps/CompleteImportStep";

const SubEventImportPlayersSteps = ({ eventId, subEventId }) => {

    const intl = useIntl();

    const history = useHistory();

    const { state, setStepState } = useContext(ImportPlayersContext);

    const { stepState } = state;

    const { data, loading, error } = useQuery(SUB_EVENT_IMPORT_PLAYERS, {
        variables: {
            id: subEventId,
        },
    });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (loading) {
        return <CircularProgress />;
    }

    if (!data || !data.subEvent) {
        return <Redirect to={getAdminPath(`event/${eventId}/sub-event/${subEventId}`)} />;
    }

    const { subEvent } = data;

    const commonProps = {
        subEvent,
        setStepState,
        intl,
    }

    const steps = [
        <PlayersImportDownloadFile {...commonProps} />,
        <CreateRowsStep {...commonProps} />,
        <PayerStep  {...commonProps} />,
        <CompleteImportStep {...commonProps} />,
    ]

    return (
        <>
            <PageHeader
                title={
                    <span>
                        <IntlMessages id="common.import_players" />
                        :&nbsp;{subEvent.name}
                    </span>
                }
                onBack={() => {
                    history.push(getAdminPath(`event/${eventId}/sub-event/${subEventId}/subscribers`));
                }}
            />

            <Row className="gx-mt-4">
                <Col xs={24}>
                    <Steps
                        current={stepState}
                        onChange={(step) => {
                            setStepState(step);
                        }}>
                        <Steps.Step disabled={false} title={<IntlMessages id="import_players.download_file" />} />
                        <Steps.Step disabled={false} title={<IntlMessages id="import_players.add_players" />} />
                        <Steps.Step disabled={stepState < 2} title={<IntlMessages id="import_players.payer" />} />
                        <Steps.Step disabled={stepState < 3} title={<IntlMessages id="import_players.result" />} />
                    </Steps>
                </Col>

                <Col xs={24}>
                    <div className="gx-py-5">
                        {steps[stepState]}
                    </div>
                </Col>
            </Row>
            <DevButton path={__filename} />
        </>
    )
}

const SubEventImportPlayers = ({ match }) => {

    const { params } = match;

    return (
        <ImportPlayersProvider >
            <SubEventImportPlayersSteps eventId={params.event_id} subEventId={params.id} />
        </ImportPlayersProvider>

    )
}

export default SubEventImportPlayers;