import React, { useCallback, useReducer, useRef, useEffect } from 'react';
import { includes, startsWith, isString, keys, isBoolean } from 'lodash';
import { usePersistedReducer } from '../../../../../hooks/usePersistedReducer';
import { isDispatchThunk, logNextState, logPreviousState } from '../../../../../util/reducer';

export const ImportPlayersContext = React.createContext();

const TEST_PLAYERS = [
    {
        index: 1,
        first_name: 'John',
        last_name: 'Doe',
        email: 'jd@gmail.com',
        phone: '+1234567890',
        birthday: '01/01/1990',
        citizenship: 'IT',
    },
    {
        index: 2,
        first_name: 'Jane',
        last_name: 'Davaz',
        email: 'jdav@gmail.com',
        phone: '+1234567890',
        birthday: '01/01/1995',
        citizenship: 'BR',
    }
];

export const playersToMutation = (players, subEvent) => {

    return players.map((player) => {
        return keys(player).reduce((acc, key) => {

            if (key === 'birthday') {

                if (player.birthday && isString(player.birthday)) {
                    const birthday = player.birthday.split('/');
                    acc.birthday = `${birthday[2]}-${birthday[1]}-${birthday[0]}`;
                } else if (player.birthday && player.birthday?.isValid()) {
                    acc.birthday = player.birthday.format('YYYY-MM-DD');
                } else {
                    acc.birthday = null;
                }

            } else if (startsWith(key, 'cf_')) {

                if (isBoolean(player[key])) {
                    if (player[key] === true) {
                        player[key] = 'yes';
                    } else {
                        player[key] = 'no';
                    }
                }

                acc.custom_fields.push({
                    key: key,
                    value: player[key] ? player[key].toString() : null,
                });
            } else if (startsWith(key, 'product')) {

                const [_, productId] = key.split('_');
                acc.product_variants.push({
                    key: productId,
                    value: player[key],
                });

            } else if (!includes(['location_label'], key)) {
                acc[key] = player[key];
            }

            return acc;
        }, {
            product_variants: [],
            custom_fields: [],
            event_id: subEvent.event.id,
            sub_event_id: subEvent.id,
        })
    })
}

const defaultInitialState = {
    players: [],
    validatorState: {},
    stepState: 0,
    editingKey: '',
};

function reducer(init, state, action) {
    switch (action.type) {
        case 'RESET_STATE':
            return init;
        case 'SET_STEP_STATE':
            return { ...state, stepState: action.payload };
        case 'SET_EDITING_KEY':
            return { ...state, editingKey: action.payload };
        case 'SET_VALIDATOR_STATE':
            return { ...state, validatorState: action.payload };
        case 'SET_PLAYERS':
            return { ...state, players: action.payload };
        case 'ADD_NEW_PLAYER':
            return {
                ...state,
                players: [
                    ...state.players,
                    {
                        index: state.players.length + 1,
                        first_name: '',
                        last_name: '',
                        email: '',
                        gender: '',
                        phone: '',
                        birthday: '',
                        citizenship: '',
                    }
                ]
            };
        default:
            throw new Error("ImportPlayersContext invalid reducer action");
    }
}

const getActions = (dispatch) => {
    return {
        resetState: () => dispatch({ type: 'RESET_STATE' }),
        setStepState: (stepState) => dispatch({ type: 'SET_STEP_STATE', payload: stepState }),
        setEditingKey: (editingKey) => dispatch({ type: 'SET_EDITING_KEY', payload: editingKey }),
        setValidatorState: (validatorState) => dispatch({ type: 'SET_VALIDATOR_STATE', payload: validatorState }),
        setPlayers: (players) => dispatch({ type: 'SET_PLAYERS', payload: players }),
        onAddNewPlayer: () => dispatch({ type: 'ADD_NEW_PLAYER' }),
    }
}


const useImportPlayersReducer = (
    storeKey,
    initialState,
    reducer,
    middlewares = [],
    afterDispatchMiddlewares = []
) => {
    const [state, dispatch] = storeKey
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
        usePersistedReducer(reducer.bind(null, initialState), initialState, storeKey)
        : // eslint-disable-next-line react-hooks/rules-of-hooks
        useReducer(reducer.bind(null, initialState), initialState);

    const currentRef = useRef();

    const getState = useCallback(() => state, [state]);

    useEffect(() => {
        if (!currentRef.current || !currentRef.current.action) return;
        afterDispatchMiddlewares.map((middleware) => middleware(currentRef.current.action, currentRef.current.state, state));
    }, [afterDispatchMiddlewares, state]);

    const dispatchThunk = (action) => {
        return isDispatchThunk(action)
            ? action(dispatch, getState)
            : dispatch(action);
    };

    const dispatchUsingMiddleware = (action) => {
        middlewares.map((middleware) => middleware(action, state));
        currentRef.current = { action, state };
        dispatchThunk(action);
    }

    return [state, dispatchUsingMiddleware];

}

export const ImportPlayersProvider = ({ children, storeKey = null }) => {

    const [state, dispatch] = useImportPlayersReducer(
        storeKey,
        defaultInitialState,
        reducer,
        [logPreviousState],
        [logNextState]
    );

    return (
        <ImportPlayersContext.Provider
            // value={{
            //     players,
            //     setPlayers,
            //     validatorState,
            //     setValidatorState,
            //     stepState,
            //     setStepState,
            //     editingKey,
            //     setEditingKey,
            //     onAddNewPlayer: () => {
            //         // setPlayers([
            //         //     ...players, {
            //         //         index: players.length + 1,
            //         //         first_name: '',
            //         //         last_name: '',
            //         //         email: '',
            //         //         phone: '',
            //         //         birthday: '',
            //         //         citizenship: '',
            //         //     }
            //         // ]);
            //     },
            // }}
            value={{ state, dispatch, ...getActions(dispatch) }}
        >
            {children}
        </ImportPlayersContext.Provider>
    );
}

