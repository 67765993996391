import { useMutation, useQuery } from '@apollo/client';
import { PageHeader, Row, Card, Col, Tag, Alert, Modal, message, Button, Space } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { SUB_EVENT_PLAYER } from '../../../../apollo/query/sub-event';
import BadResponse from '../../../../components/BadResponse';
import CircularProgress from '../../../../components/CircularProgress';
import CardDescriptions, { CardDescriptionsItem } from '../../../../components/Descriptions/CardDescription';
import { DATETIME_FORMAT, DATE_FORMAT } from '../../../../constants/App';
import { DANGER_COLOR, PRIMARY_COLOR, SUCCESS_COLOR } from '../../../../constants/ThemeSetting';
import { formatDate, formatDateTime } from '../../../../util/date';
import IntlMessages from '../../../../util/IntlMessages';
import { getAdminPath, getMediaURL } from '../../../../util/router';
import { formatCurrency, formatLocation } from '../../../../util/string';
import EmptyCard from '../../../../components/EmptyCard';
import { subEventHasAthleteCard, subEventHasMedicalCartificate } from './common';
import DevButton from '../../../../components/Dev/DevButton';
import SubEventBreadcrumbs from '../breadcrumbs';
import { EditOutlined, UnorderedListOutlined, MailOutlined, UserOutlined, RedoOutlined } from '@ant-design/icons';
import CommunicationCard from '../../../../components/CommunicationCard';
import { AthleteCardValidationTag } from '../../../../components/Icons/AthleteCardValidation';
import { PLAYER_CERTIFICATE_REMINDERS, PLAYER_RESEND_CONFIRMATION_EMAIL } from '../../../../apollo/mutation/event';
import { defaultCatchException, defaultMutationHandler } from '../../../../apollo/callbacks';
import { useActiveBreakpoint } from '../../../../hooks/useActiveBreakpoint';
import { getSafeISOLanguage } from '../../../../lngProvider';
import ProvidedPlayerCardModalButton from '../../../../components/Players/ProvidedPlayerCardModal';

const CustomFieldsCard = ({ player, subEvent, columns }) => {

    const intl = useIntl();

    if (!subEvent.custom_fields || !subEvent.custom_fields.length) {
        return <EmptyCard size="sm" title={<IntlMessages id="common.custom_fields" />} />;
    }

    return (
        <Card title={<IntlMessages id="common.custom_fields" />} className="gx-card">
            <CardDescriptions column={columns} className="gx-mx-0">
                {player.custom_fields.map((customField, index) => {

                    let value = '';

                    if (intl.messages[`common.${customField.value}`]) {
                        value = intl.formatMessage({ id: `common.${customField.value}` });
                    } else {
                        value = customField.value;
                    }
                    
                    return (
                        <CardDescriptionsItem key={index} label={customField.label}>
                            {value}
                        </CardDescriptionsItem>
                    );
                })}
            </CardDescriptions>
        </Card>
    );
};

const ProductsCard = ({ variants }) => {
    if (!variants || !variants.length) {
        return <EmptyCard size="sm" title={<IntlMessages id="products.products" />} />;
    }

    return (
        <Card title={<IntlMessages id="products.products" />} className="gx-card">
            <CardDescriptions column={1} className="gx-mx-0">
                {variants.map((variant, index) => {
                    return (
                        <CardDescriptionsItem key={index} label={(
                            <span>
                                {variant.product.name}
                            </span>
                        )}>
                            {(variant.attributes || []).map((attribute, index) => {
                                return (
                                    <span key={index}>
                                        {attribute.attribute_name_locale}
                                        <Tag className="gx-mx-2">{attribute.option_name_locale}</Tag>
                                    </span>
                                );
                            }
                            )}
                        </CardDescriptionsItem>
                    );
                })}
            </CardDescriptions>
        </Card>
    );
}

const ViewSubEventPlayer = ({ match }) => {
    const { params } = match;

    const intl = useIntl();

    const locale = getSafeISOLanguage(intl.locale);

    const history = useHistory();

    const activeBreakpoint = useActiveBreakpoint();

    const cardDescriptionColumns = activeBreakpoint === 'XS' ? 1 : 2;

    const [playerCertificateReminders, { loading: remindersLoading }] = useMutation(PLAYER_CERTIFICATE_REMINDERS);
    const [resendConfirmationEmail, { loading: resendConfirmationLoading }] = useMutation(PLAYER_RESEND_CONFIRMATION_EMAIL);

    const { data, loading, error, refetch } = useQuery(SUB_EVENT_PLAYER, {
        variables: {
            playerId: params.player_id,
            subEventId: params.id,
            language: locale,
        },
        context: {
            headers: {
                'x-locale': locale,
            },
        },
    });

    const onResendConfirmationEmail = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'common.confirmation_email_resend_modal_title' }),
            cancelText: intl.formatMessage({ id: 'common.cancel' }),
            okButtonProps: {
                loading: resendConfirmationLoading,
            },
            onOk: () => {
                resendConfirmationEmail({
                    variables: {
                        id: player.id,
                    },
                })
                    .then(
                        defaultMutationHandler({
                            successCallback: () => {
                                message.success(intl.formatMessage({ id: 'common.operation_successful' }));
                                refetch();
                            },
                            errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                        })
                    )
                    .catch((e) => defaultCatchException(e, intl));
            },
        });
    }

    const onRemindCertificate = (player) => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'common.certificate_remainder_modal_title' }),
            content: intl.formatMessage({ id: 'common.certificate_remainder_modal_text' }),
            okText: intl.formatMessage({ id: 'common.confirm' }),
            cancelText: intl.formatMessage({ id: 'common.cancel' }),
            okButtonProps: {
                loading: remindersLoading,
            },
            onOk: () => {
                playerCertificateReminders({
                    variables: {
                        players: [player.id],
                    },
                })
                    .then(
                        defaultMutationHandler({
                            successCallback: () => {
                                message.success(intl.formatMessage({ id: 'common.certificate_multi_remainder_email_sent' }));
                                refetch();
                            },
                            errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                        })
                    )
                    .catch((e) => defaultCatchException(e, intl));
            },
        });
    };

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (loading) {
        return <CircularProgress />;
    }

    if (!data || !data.subEvent || !data.player) {
        return <Redirect to={getAdminPath(`event/${params.event_id}/sub-event/${params.id}`)} />;
    }

    const { subEvent, player } = data;

    return (
        <>
            <Row>
                <Col md={24}>
                    <SubEventBreadcrumbs subEvent={subEvent} section={<IntlMessages id={`common.players`} />} />
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <PageHeader
                        onBack={() => history.goBack()}
                        title={
                            <span>
                                <IntlMessages id="common.subscription" />
                                <span className="gx-px-1">|</span>
                                {subEvent.name}
                            </span>
                        }
                        extra={
                            <Space wrap>
                                <a
                                    className="ant-btn"
                                    key="sheet"
                                    href={`${process.env.REACT_APP_FRONTEND_URL}/tickets/${subEvent.id}/player/${player.id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <UserOutlined /> <IntlMessages id="common.player_sheet" />
                                </a>

                                <Link key="list" className="ant-btn" to={getAdminPath(`event/${subEvent.event.id}/sub-event/${subEvent.id}/subscribers`)}>
                                    <UnorderedListOutlined /> <IntlMessages id="common.subscribers_table" />
                                </Link>

                                <Link
                                    key="update"
                                    className="ant-btn ant-btn-primary"
                                    to={getAdminPath(`event/${subEvent.event.id}/sub-event/${subEvent.id}/subscriber/update/${player.id}`)}
                                >
                                    <EditOutlined />
                                    &nbsp;
                                    <IntlMessages id="common.edit" />
                                </Link>
                            </Space>
                        }
                    />
                </Col>
                {player.deleted_at && (
                    <Col xs={24}>
                        <Alert
                            message={
                                <span>
                                    <IntlMessages id="common.player_deleted" />
                                    <br />
                                </span>
                            }
                            type="warning"
                            showIcon
                            className="gx-mb-3"
                        />
                    </Col>
                )}
                <Col xs={24} md={24} lg={12}>
                    <Card title={<IntlMessages id="common.registry" />} className="gx-card">
                        <CardDescriptions column={cardDescriptionColumns} className="gx-mx-0">
                            <CardDescriptionsItem label={<IntlMessages id="common.first_name" />}>{player.first_name}</CardDescriptionsItem>

                            <CardDescriptionsItem label={<IntlMessages id="common.last_name" />}>{player.last_name}</CardDescriptionsItem>

                            {player.gender && (
                                <CardDescriptionsItem label={<IntlMessages id="common.gender" />}>
                                    <IntlMessages id={`common.gender_${player.gender}`} />
                                </CardDescriptionsItem>
                            )}

                            {player.birthday && (
                                <CardDescriptionsItem label={<IntlMessages id="common.birthday" />}>
                                    {formatDate(player.birthday, DATE_FORMAT, 'YYYY-MM-DD')}
                                </CardDescriptionsItem>
                            )}

                            {player.location && (
                                <CardDescriptionsItem label={<IntlMessages id="common.location" />}>{formatLocation(player.location)}</CardDescriptionsItem>
                            )}

                            {player.citizenship_label && (
                                <CardDescriptionsItem label={<IntlMessages id="common.citizenship_country" />}>{player.citizenship_label}</CardDescriptionsItem>
                            )}
                        </CardDescriptions>
                    </Card>

                    <Card title={<IntlMessages id="common.subscription" />} className="gx-card">
                        <CardDescriptions column={cardDescriptionColumns} className="gx-mx-0">
                            <CardDescriptionsItem label={<IntlMessages id="common.email" />}>{player.email}</CardDescriptionsItem>

                            <CardDescriptionsItem label={<IntlMessages id="common.phone" />}>{player.phone}</CardDescriptionsItem>

                            <CardDescriptionsItem label={<IntlMessages id="common.confirm" />}>
                                {player.confirmed ? (
                                    <Tag color={SUCCESS_COLOR} className="gx-text-uppercase">
                                        <IntlMessages id="common.yes" />
                                    </Tag>
                                ) : (
                                    <Tag color={DANGER_COLOR} className=" gx-text-uppercase">
                                        <IntlMessages id="common.no" />
                                    </Tag>
                                )}
                            </CardDescriptionsItem>

                            <CardDescriptionsItem label={<IntlMessages id="common.confirmation_email" />}>
                                {player.confirm_email ? (
                                    <span>
                                        <Tag color={SUCCESS_COLOR} className="gx-text-uppercase">
                                            <IntlMessages id="common.email_sent" />
                                        </Tag>

                                        <Button
                                            className='gx-mb-0'
                                            size='small'
                                            onClick={() => {
                                                onResendConfirmationEmail();
                                            }}
                                        >
                                            <RedoOutlined className='gx-mr-1' /><IntlMessages id="common.resend" />
                                        </Button>
                                    </span>
                                ) : (
                                    <Tag color={DANGER_COLOR} className=" gx-text-uppercase">
                                        <IntlMessages id="common.email_not_sent" />
                                    </Tag>
                                )}
                            </CardDescriptionsItem>

                            <CardDescriptionsItem label={<IntlMessages id="common.bib_number" />}>
                                {player.tracking?.number ? <Tag color={PRIMARY_COLOR}>{player.tracking.number}</Tag> : '-'} <span className="gx-pr-2">|</span>{' '}
                                {player.tracking?.number && player.tracking?.assignment_mode ? (
                                    <span>
                                        <Tag>
                                            <IntlMessages id={`tracking.${player.tracking?.assignment_mode.toLowerCase()}`} />
                                        </Tag>
                                    </span>
                                ) : null}
                            </CardDescriptionsItem>

                            <CardDescriptionsItem label={<IntlMessages id="common.createdAt" />}>
                                {formatDate(player.created_at, DATETIME_FORMAT)}
                            </CardDescriptionsItem>

                            <CardDescriptionsItem label={<IntlMessages id="common.updatedAt" />}>
                                {formatDate(player.updated_at, DATETIME_FORMAT)}
                            </CardDescriptionsItem>

                            <CardDescriptionsItem label={<IntlMessages id="common.retired" />}>
                                {player.retired ? (
                                    <Tag color={DANGER_COLOR} className="gx-text-uppercase">
                                        <IntlMessages id="common.yes" />
                                    </Tag>
                                ) : (
                                    <Tag color={SUCCESS_COLOR} className=" gx-text-uppercase">
                                        <IntlMessages id="common.no" />
                                    </Tag>
                                )}
                            </CardDescriptionsItem>

                            {player.team && player.team.id && (
                                <CardDescriptionsItem label={<IntlMessages id="common.team" />}>{player.team?.name || '-'}</CardDescriptionsItem>
                            )}

                            <CardDescriptionsItem column={1} label={<IntlMessages id="common.organization_note" />}>
                                {player.organization_note || '-'}
                            </CardDescriptionsItem>
                        </CardDescriptions>
                    </Card>

                    <CustomFieldsCard player={player} subEvent={subEvent} columns={cardDescriptionColumns} />

                    <ProductsCard variants={player.product_variants} />
                </Col>

                <Col xs={24} md={24} lg={12}>
                    {player.payment && (
                        <Card
                            title={<IntlMessages id="common.payment" />}
                            className="gx-card"
                            actions={[
                                <Link to={getAdminPath(`event/${subEvent.event.id}/payment/view/${player.payment.id}`)}>
                                    <IntlMessages id="common.payment_sheet" />
                                </Link>,
                            ]}
                        >
                            <CardDescriptions title={<IntlMessages id="common.player_payment_data" />} column={cardDescriptionColumns} className="gx-mx-0">
                                <CardDescriptionsItem label={<IntlMessages id="common.subscription_paid" />}>
                                    {Boolean(player.payment.transaction.paid_at) ? (
                                        <Tag color={SUCCESS_COLOR} className="gx-text-uppercase">
                                            <IntlMessages id="common.yes" />
                                        </Tag>
                                    ) : (
                                        <Tag color={DANGER_COLOR} className=" gx-text-uppercase">
                                            <IntlMessages id="common.no" />
                                        </Tag>
                                    )}
                                </CardDescriptionsItem>

                                <CardDescriptionsItem label={<IntlMessages id="common.paid_at" />}>
                                    {player.payment.transaction.paid_at ? formatDateTime(player.payment.transaction.paid_at) : '-'}
                                </CardDescriptionsItem>

                                <CardDescriptionsItem label={<IntlMessages id="common.price" />}>
                                    {formatCurrency(player.order?.price / 100, 2, subEvent.event.payments.location.currency.iso)}
                                </CardDescriptionsItem>

                                <CardDescriptionsItem label={<IntlMessages id="common.additions" />}>
                                    {formatCurrency(player.order?.additions / 100, 2, subEvent.event.payments.location.currency.iso)}
                                </CardDescriptionsItem>

                                <CardDescriptionsItem label={<IntlMessages id="common.subtotal" />}>
                                    {formatCurrency(player.order?.subtotal / 100, 2, subEvent.event.payments.location.currency.iso)}
                                </CardDescriptionsItem>

                                <CardDescriptionsItem label={<IntlMessages id="common.fee" />}>
                                    {formatCurrency(player.order?.fee / 100, 2, subEvent.event.payments.location.currency.iso)}
                                </CardDescriptionsItem>

                                <CardDescriptionsItem label={<IntlMessages id="common.total" />}>
                                    {formatCurrency(player.order?.total / 100, 2, subEvent.event.payments.location.currency.iso)}
                                </CardDescriptionsItem>
                            </CardDescriptions>
                        </Card>
                    )}

                    {subEventHasMedicalCartificate(subEvent) && (
                        <Card
                            title={<IntlMessages id="common.certificate" />}
                            className="gx-card"
                            actions={[
                                player.certificate?.path ? (
                                    <a href={getMediaURL(player.certificate?.path)} target="_blank" rel="noreferrer">
                                        <IntlMessages id="common.view_certificate" />
                                    </a>
                                ) : null,

                                !player.certificate?.path ? (
                                    <Button
                                        className="gx-mb-0"
                                        key="certificate_reminder"
                                        icon={<MailOutlined className="gx-pr-1" />}
                                        onClick={() => onRemindCertificate(player)}
                                    >
                                        <IntlMessages id="common.remainder_email" />
                                    </Button>
                                ) : null,
                            ].filter(Boolean)}
                        >
                            <CardDescriptions column={cardDescriptionColumns} className="gx-mx-0">
                                <CardDescriptionsItem label={<IntlMessages id="common.uploaded" />}>
                                    {Boolean(player.certificate?.path) ? (
                                        <Tag color={SUCCESS_COLOR} className="gx-text-uppercase">
                                            <IntlMessages id="common.yes" />
                                        </Tag>
                                    ) : (
                                        <Tag color={DANGER_COLOR} className=" gx-text-uppercase">
                                            <IntlMessages id="common.no" />
                                        </Tag>
                                    )}
                                </CardDescriptionsItem>

                                <CardDescriptionsItem label={<IntlMessages id="common.expireAt" />}>
                                    {player.certificate?.expire_at ? formatDate(player.certificate?.expire_at) : '-'}
                                </CardDescriptionsItem>

                                <CardDescriptionsItem label={<IntlMessages id="common.confirmed" />}>
                                    {Boolean(player.certificate?.organization_confirm) ? (
                                        <Tag color={SUCCESS_COLOR} className="gx-text-uppercase">
                                            <IntlMessages id="common.confirmed" />
                                        </Tag>
                                    ) : (
                                        <Tag color={DANGER_COLOR} className=" gx-text-uppercase">
                                            <IntlMessages id="common.not_confirmed" />
                                        </Tag>
                                    )}
                                </CardDescriptionsItem>
                            </CardDescriptions>
                        </Card>
                    )}
                    {subEventHasAthleteCard(subEvent) && (
                        <Card
                            className="gx-card"
                            title={<IntlMessages id="common.athlete_card" />}
                            extra={<ProvidedPlayerCardModalButton number={player.athlete_card?.number} type={player.athlete_card?.type} />}
                            actions={[
                                player.athlete_card?.file_path ? (
                                    <a key="view" href={getMediaURL(player.athlete_card?.file_path)} target="_blank" rel="noreferrer">
                                        <IntlMessages id="common.view_athlete_card" />
                                    </a>
                                ) : null,
                            ].filter(Boolean)}
                        >
                            <CardDescriptions column={cardDescriptionColumns} className="gx-mx-0">
                                <CardDescriptionsItem label={<IntlMessages id="common.athlete_card" />}>{player.athlete_card?.label}</CardDescriptionsItem>

                                <CardDescriptionsItem label={<IntlMessages id="common.athlete_card_number" />}>
                                    {player.athlete_card?.number}
                                </CardDescriptionsItem>

                                <CardDescriptionsItem label={<IntlMessages id="common.athlete_card_provider_verification" />}>
                                    {player.athlete_card?.validation !== null ? <AthleteCardValidationTag card={player.athlete_card} /> : '-'}
                                </CardDescriptionsItem>

                                <CardDescriptionsItem label={<IntlMessages id="common.organization_code" />}>
                                    {player.athlete_card?.organization_code}
                                </CardDescriptionsItem>

                                <CardDescriptionsItem label={<IntlMessages id="common.organization_name" />}>
                                    {player.athlete_card?.organization_name}
                                </CardDescriptionsItem>
                            </CardDescriptions>
                        </Card>
                    )}

                    <CommunicationCard communications={player?.communications} />
                </Col>
            </Row>
            <DevButton path={__filename} />
        </>
    );
};

export default ViewSubEventPlayer;
