import React from 'react';
import { isDevelopment } from 'util/config';
import './style.less';
import { Grid } from 'antd';
import { getActiveBreakpoint } from '../../hooks/useActiveBreakpoint';

const { useBreakpoint } = Grid;

const DevButton = ({ path }) => {
    const screens = useBreakpoint();

    if (!isDevelopment || !process.env.REACT_APP_ROOT_PATH) {
        return null;
    }

    return (
        <div className="fixed-widgets">
            <a
                className="ant-avatar ant-avatar-circle ant-avatar-icon ant-dropdown-trigger fixed-widgets-avatar"
                href={`vscode://file/${process.env.REACT_APP_ROOT_PATH}/${path}`}
            >
                <small>{getActiveBreakpoint(screens)}</small>
            </a>
        </div>
    );
};

export default DevButton;
